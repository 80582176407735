import React from 'react'
import { useDispatch, useSelector } from "react-redux";
import { useForm } from '../../hooks/useForm';
import { crearInasistencia, startUploading } from '../../action/inasistencia';
import Swal from 'sweetalert2';


export const FormInasistencia = () => {
    const dispatch = useDispatch();
    const { doc } = useSelector( state => state.inasistencia);
    const {apoderadoActivo} = useSelector( state => state.apoderado);
    const {estudianteActivo} = useSelector( state => state.estudiante);
    
    const [ inasistencia, handleInputChangeIna ] = useForm({
        motivo:'',
        fechaInicio:'',
        fechaTermino:'',
        documento:'',
        descripcion:'',
        apoderado:'',
        estudiante:'',
    });

    
    const handleEnviar = (e) => {
        e.preventDefault();
      
        if(inasistencia.motivo.trim() === '' || inasistencia.fechaInicio.trim() === '' || inasistencia.fechaTermino.trim() === '' || inasistencia.descripcion.trim() === ''){
            return Swal.fire({
                icon: 'warning',
                title: 'Faltan datos inasistencia',
                showConfirmButton: false,
                timer: 1500
            })
        }
        inasistencia.apoderado = apoderadoActivo._id;
        inasistencia.estudiante = estudianteActivo._id;
        inasistencia.documento = doc;
        dispatch(crearInasistencia(inasistencia));
        
    }
   

    
   


    
    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file){
            dispatch(startUploading(file));
        } else {
            dispatch(startUploading());
        }
    }

    return (
        <form className="card">
            <div className="card-body">
              
                
                <div className="row">
                    <div className="col-12">
                        <h4>Datos Inasistencia</h4>
                    </div>
                    <div className="col-3">
                        <div className="mb-3">
                            <label htmlFor="motivo" className="form-label">Motivo Inasistencia: </label>
                            <input 
                                type="text" 
                                className="form-control" 
                                id="motivo" 
                                name="motivo"
                                value={inasistencia.motivo} 
                                onChange={handleInputChangeIna}
                            />
                        </div>
                    </div>
                    <div className="col-3">
                        <div className="mb-3">
                            <label htmlFor="fechaInicio" className="form-label">Fecha Inicio Inasistencia: </label>
                            <input 
                                type="date" 
                                className="form-control" 
                                id="fechaInicio" 
                                name="fechaInicio"
                                value={inasistencia.fechaInicio} 
                                onChange={handleInputChangeIna}
                            />
                        </div>
                    </div>
                    <div className="col-3">
                        <div className="mb-3">
                            <label htmlFor="fechaTermino" className="form-label">Fecha Termino Inasistencia: </label>
                            <input 
                                type="date" 
                                className="form-control" 
                                id="fechaTermino" 
                                name="fechaTermino"
                                value={inasistencia.fechaTermino} 
                                onChange={handleInputChangeIna}
                            />
                        </div>
                    </div>
                    <div className="col-6">
                        <div className="mb-3">
                            <label htmlFor="file" className="form-label">Documento</label>
                            <input 
                                className="form-control" 
                                type="file" 
                                id="file"
                                name="file"
                                onChange={handleFileChange}
                            />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <div className="mb-3">
                            <label htmlFor="descripcion" className="form-label">Descripcion</label>
                            <textarea 
                                className="form-control" 
                                id="descripcion"
                                name="descripcion"
                                value={inasistencia.descripcion} 
                                onChange={handleInputChangeIna}
                                rows="3"
                            ></textarea>
                        </div>
                    </div>
                </div>
                <button 
                    type="submit" 
                    className="btn btn-primary" 
                    onClick={ handleEnviar }
                >Enviar</button>
            </div>
      </form>
    )
}

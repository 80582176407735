import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { obtenerApoderados } from '../../action/apoderado';
import { obtenerCursos } from '../../action/curso';
import { obtenerEstudiantes } from '../../action/estudiante';
import { FormApoderado } from "./FormApoderado";
import { FormEstudiante } from "./FormEstudiante";
import { FormInasistencia } from './FormInasistencia'

export const IndexScreen = () => {
    const dispatch = useDispatch()
    const {cursos} = useSelector(state => state.curso);
    const {estudiantes} = useSelector(state => state.estudiante);
    const {apoderados} = useSelector(state => state.apoderado);
    const { formCrearInasistencia, formCrearEstudiante, formCrearApoderado} = useSelector(state => state.admin)

    useEffect(() => {
        const obtCursos = () => dispatch(obtenerCursos());
        const obtEstudiantes = () => dispatch(obtenerEstudiantes());
        const obtApoderados = () => dispatch(obtenerApoderados());
        obtCursos();
        obtEstudiantes();
        obtApoderados();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="row justify-content-center">
          <div className="col-12">
            <h2 className="text-light mt-3">Formulario Justificacion de Inasistencia</h2>
          </div>
          <div className="col-9 mt-3" >
            {formCrearEstudiante && <FormEstudiante estudiantes={estudiantes}  cursos={cursos} />}
            {formCrearApoderado && <FormApoderado apoderados={apoderados} />}
            {formCrearInasistencia && <FormInasistencia/>}
            
            
          
          </div>
        </div>
    )
}

import React, { useEffect } from 'react'
import { useDispatch, useSelector } from "react-redux";
import { useForm } from '../../hooks/useForm';
import { activarApoderado, crearApoderado } from '../../action/apoderado';
import {  validate, format } from 'rut.js'
import Swal from 'sweetalert2';
import { openFormCrearInasistencia } from '../../action/admin';

export const FormApoderado = ({apoderados}) => {
    const dispatch = useDispatch();
    const { apoderadoActivo } = useSelector( state => state.apoderado);
    const [ apoderado, handleInputChangeApo, resetApo ] = useForm({
        rut:'',
        nombres:'',
        apellidoP:'',
        apellidoM:'',
    });

    
    const apo = apoderados.find(e=> e.rut === format(apoderado.rut))

    const handleRutApoderado = () => {
        if(!validate(format(apoderado.rut))) {
            resetApo();
            return Swal.fire({
                icon: 'warning',
                title: 'El rut no es valido',
                showConfirmButton: false,
                timer: 1500
            })
        } 
        if(apo) dispatch(activarApoderado(apo));
    }
    const handleEnviar = (e) => {
        e.preventDefault();
        if(Object.entries(apoderadoActivo).length===0) {
            apoderado.rut = format(apoderado.rut);
            if( apoderado.nombres.trim() === '' || apoderado.apellidoM.trim() === '' || apoderado.apellidoP.trim() === '' || apoderado.rut.trim() === '' ){
                return Swal.fire({
                    icon: 'warning',
                    title: 'Faltan datos apoderado',
                    showConfirmButton: true,
                    timer: 1500
                })
            }
            dispatch(crearApoderado(apoderado))
        } else {
            dispatch(openFormCrearInasistencia())
        }
    }

     
    useEffect(() => {
        if(apo){
            apoderado.nombres = apo.nombres
            apoderado.apellidoP = apo.apellidoP
            apoderado.apellidoM = apo.apellidoM
            apoderado._id = apo._id
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[apo])
    return (
        <form className="card">
            <div className="card-body">
                <div className="row">
                    <div className="col-12">
                        <h4>Datos Apoderado</h4>
                    </div>
                    <div className="col-4">
                        <div className="mb-3">
                            <label htmlFor="apoderado.rut" className="form-label">Rut Apoderado: </label>
                            <input 
                                type="text" 
                                className="form-control" 
                                id="rut" 
                                name="rut"
                                value={format(apoderado.rut)} 
                                onChange={handleInputChangeApo}
                                onBlur={handleRutApoderado}
                            />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-6">
                        <div className="mb-3">
                            <label htmlFor="nombres" className="form-label"> Nombres: </label>
                            <input 
                                type="text" 
                                className="form-control" 
                                id="nombres" 
                                name="nombres"
                                value={apoderado.nombres} 
                                onChange={handleInputChangeApo}
                            />
                        </div>
                    </div>
                    <div className="col-3">
                        <div className="mb-3">
                            <label htmlFor="apellidoP" className="form-label">Apellido Paterno: </label>
                            <input 
                                type="text" 
                                className="form-control" 
                                id="apellidoP" 
                                name="apellidoP"
                                value={apoderado.apellidoP} 
                                onChange={handleInputChangeApo}
                            />
                        </div>
                    </div>
                    <div className="col-3">
                        <div className="mb-3">
                            <label htmlFor="apellidoM" className="form-label">Apellido Materno: </label>
                            <input 
                                type="text" 
                                className="form-control" 
                                id="apellidoM" 
                                name="apellidoM"
                                value={apoderado.apellidoM} 
                                onChange={handleInputChangeApo}
                            />
                        </div>
                    </div>
                </div>
                <button 
                    type="submit" 
                    className="btn btn-primary" 
                    onClick={ handleEnviar }
                >Siguiente</button>
            </div>
        </form>
    )
}

import * as React from "react"
import { IndexScreen } from "../components/index/IndexScreen"
import Layout from '../components/layout'


// markup
const IndexPage = () => {
  return (
    <Layout>
      <div className="container-xl">
        <IndexScreen />
      </div>
    </Layout>
  )
}

export default IndexPage

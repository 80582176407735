import React, { useEffect } from 'react'
import { useDispatch, useSelector } from "react-redux";
import { useForm } from '../../hooks/useForm';
import { activarEstudiante, crearEstudiante } from '../../action/estudiante';
import {  validate, format } from 'rut.js'
import Swal from 'sweetalert2';
import { openFormCrearApoderado } from '../../action/admin';

export const FormEstudiante = ({cursos, estudiantes}) => {
    const dispatch = useDispatch();
    const { estudianteActivo } = useSelector( state => state.estudiante);
    
    const [ estudiante, handleInputChangeEst, resetEst ] = useForm({
        rut:'',
        nombres:'',
        apellidoP:'',
        apellidoM:'',
        curso:'',
    });
    
    const est = estudiantes.find(e=> e.rut === format(estudiante.rut))
    
    const handleRutEstudiante = () => {
        if(!validate(format(estudiante.rut))) {
            resetEst();
            return Swal.fire({
                icon: 'warning',
                title: 'El rut no es valido',
                showConfirmButton: false,
                timer: 1500
            })
        } 
        if(est) dispatch(activarEstudiante(est));
    }

    const handleEnviar = (e) => {
        e.preventDefault();
        if(Object.entries(estudianteActivo).length===0) {
            if( estudiante.nombres.trim() === '' || estudiante.apellidoM.trim() === '' || estudiante.apellidoP.trim() === '' || estudiante.rut.trim() === '' || estudiante.curso.trim() === '' ){
                return Swal.fire({
                    icon: 'warning',
                    title: 'Faltan datos estudiante',
                    showConfirmButton: false,
                    timer: 1500
                })
            }
            estudiante.rut = format(estudiante.rut);
            dispatch(crearEstudiante(estudiante))
        } else {
            dispatch(openFormCrearApoderado())
        }
    }

    useEffect(() => {
        if(est){
            estudiante.nombres = est.nombres
            estudiante.apellidoP = est.apellidoP
            estudiante.apellidoM = est.apellidoM
            estudiante._id = est._id
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[est])

   
    return (
        <form className="card">
            <div className="card-body">
                <div className="row">
                    <div className="col-12">
                        <h4>Datos Estudiante</h4>
                    </div>
                    <div className="col-4">
                        <div className="mb-3">
                            <label htmlFor="rut" className="form-label">Rut Estudiante: </label>
                            <input 
                                type="text" 
                                className="form-control" 
                                id="rut" 
                                name="rut"
                                value={format(estudiante.rut)} 
                                onChange={handleInputChangeEst}
                                onBlur={handleRutEstudiante}
                            />
                        </div>
                    </div>
                    <div className="col-4">
                        <div className="mb-3">
                            <label htmlFor="curso" className="form-label">Curso Estudiante: </label>
                            <select 
                                className="form-select" 
                                aria-label="curso"
                                value={estudiante.curso}
                                name="curso"
                                onChange={handleInputChangeEst}
                            >
                                <option value="">Seleccione...</option>
                                {cursos && cursos.map( e => (
                                    <option 
                                        key={e._id} 
                                        value={e._id}
                                    >{e.nombre}</option>
                                ))}
                            </select>
                        </div>
                    </div>
                    <div className="col-9"></div>
                </div>
                <div className="row">
                    <div className="col-6">
                        <div className="mb-3">
                            <label htmlFor="nombres" className="form-label"> Nombres: </label>
                            <input 
                                type="text" 
                                className="form-control" 
                                id="nombres" 
                                name="nombres"
                                value={estudiante.nombres} 
                                onChange={handleInputChangeEst}
                            />
                        </div>
                    </div>
                    <div className="col-3">
                        <div className="mb-3">
                            <label htmlFor="apellidoP" className="form-label">Apellido Paterno: </label>
                            <input 
                                type="text" 
                                className="form-control" 
                                id="apellidoP" 
                                name="apellidoP"
                                value={estudiante.apellidoP} 
                                onChange={handleInputChangeEst}
                            />
                        </div>
                    </div>
                    <div className="col-3">
                        <div className="mb-3">
                            <label htmlFor="apellidoM" className="form-label">Apellido Materno: </label>
                            <input 
                                type="text" 
                                className="form-control" 
                                id="apellidoM" 
                                name="apellidoM"
                                value={estudiante.apellidoM} 
                                onChange={handleInputChangeEst}
                            />
                        </div>
                    </div>
                </div>
                <button 
                    type="submit" 
                    className="btn btn-primary" 
                    onClick={ handleEnviar }
                >Siguiente</button>
            </div>
        </form>
    )
}
